.region-dialog[data-v-3342b57f] .el-dialog {
  position: relative;
}
.region-dialog[data-v-3342b57f] .el-dialog__body {
  overflow-y: auto;
  height: 400px;
}
.region-dialog[data-v-3342b57f] .el-dialog__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20001;
}
.formContentBox[data-v-3342b57f] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-3342b57f] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.formTopic[data-v-3342b57f] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shutContent[data-v-3342b57f] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.deptBox[data-v-3342b57f] {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.unitBox[data-v-3342b57f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.unitTitle[data-v-3342b57f] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}